import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["icon"];

  toggle() {
    const iconTarget = this.iconTarget;

    // Retrieve the classes from data attributes
    const iconUp = this.data.get("iconUp");
    const iconDown = this.data.get("iconDown");

    // Toggle between the provided icon classes

    if (iconTarget.className === iconUp) {
      iconTarget.className = iconDown;
    } else {
      iconTarget.className = iconUp;
    }
  }
}
