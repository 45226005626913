import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  handleDropdownItemClick(event) {
    const clickedDropdownItem = $(event.target).closest('.dropdown-item');
    // If we click the item and it's already active, don't allow bootstrap to toggle
    if (clickedDropdownItem.hasClass('active')) {
      // Remove collapsable behavior
      clickedDropdownItem.attr('data-toggle', '');
    } else {
      // Add back collapsable behavior
      clickedDropdownItem.attr('data-toggle', 'collapse');
      // Remove active class from all items and add it to the clicked item
      clickedDropdownItem.closest('.dropdown-menu').find('.dropdown-item').removeClass('active');
      // Hightlight the clicked item
      clickedDropdownItem.addClass('active');
    }
  }

  handleFiltersReset(event) {
    const clickedButton = $(event.target);
    const filterForm = clickedButton.closest('form');
    filterForm.find('input[type="checkbox"]').val(''); // Clear out all checkboxes
    filterForm.trigger('submit');
  }

  handleFilterBtnClick(event) {
    const clickedElement = $(event.target);
    let filterBtn = clickedElement;
    if (clickedElement.hasClass('fa fa-times')) {
      filterBtn = clickedElement.closest('.btn');
    }
    const filterIdentifiers = filterBtn.attr('id');
    const paramName = filterIdentifiers.split('-')[0] + '[]'; // Add [] to match how the url has the param
    const paramId = filterIdentifiers.split('-')[1];

    // Remove param from URL by param name and then id
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // Get all values for param name
    const paramValues = urlParams.getAll(paramName);
    // Remove id from param values
    const newParamValues = paramValues.filter(value => value !== paramId);
    // Update the url
    urlParams.delete(paramName);
    for (const value of newParamValues) {
      urlParams.append(paramName, value);
    }
    // Update the URL
    const newUrl = window.location.pathname + '?' + urlParams.toString();
    window.history.pushState({}, '', newUrl);
    // Reload the page
    window.location.reload();
  }
}
