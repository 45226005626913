import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggleDisplayPassword(event) {
    const iconClicked = $(event.target);
    const inputField = $(iconClicked.siblings()[0]);

    if (iconClicked.hasClass('fa-eye')) {
      iconClicked.removeClass('fa-eye').addClass('fa-eye-slash');
      inputField.attr('type', 'password');
    } else {
      iconClicked.removeClass('fa-eye-slash').addClass('fa-eye');
      inputField.attr('type', 'text');
    }
  }
}
