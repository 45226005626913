import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["icon"];

  connect() {
    this.endpointUrl = this.element.dataset.selectionEndpointUrl;  // Fetch endpoint from data attribute
  }

  select(event) {
    event.preventDefault();  // Prevent the default form submission or page reload
    const button = event.currentTarget;
    const selectedValue = button.dataset.value;

    // Clear previous selection
    this.clearSelections();
    // Send selected value to the backend
    this.updateBackend(selectedValue, button);
  }

  clearSelections() {
    // Remove 'fas' (filled) class and add 'far' (unfilled) for all icons
    this.iconTargets.forEach((icon) => {
      icon.classList.remove('fas');
      icon.classList.add('far');
    });
  }

  updateSelection(button, active) {
    // Get the icon inside the button and update it to 'fas' (filled)
    const icon = button.querySelector('i');

    if (active) {
      icon.classList.remove('far');
      icon.classList.add('fas');
    } else {
      icon.classList.remove('fas');
      icon.classList.add('far');
    }
  }

  updateBackend(selectedValue, button) {
    fetch(this.endpointUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({ selection: selectedValue })
    })
      .then(response => response.json())
      .then(data => {
        console.log("Selection updated:", data);
        this.updateSelection(button, data.selection);
      })
      .catch(error => {
        console.error("Error updating selection:", error);
      });
  }
}
