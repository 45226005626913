// app/javascript/controllers/toggle_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon"]

  toggle(event) {
    event.preventDefault()
    const link = this.element.dataset.link
    const inactveIcon = this.element.dataset.inactiveIcon
    const activeIcon = this.element.dataset.activeIcon

    fetch(link, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        "Accept": "text/vnd.turbo-stream.html"
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.icon_active) {
          this.iconTarget.className = activeIcon
        } else {
          this.iconTarget.className = inactveIcon
        }
      })
      .catch(error => console.error('Error:', error))
  }
}
